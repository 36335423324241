import React, { ReactElement, useEffect, useState } from 'react';
import { usePageTheme, useApp } from '@Context/AppContext';
import SEO from '@Components/SEO';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import '@GraphQL/fragments';
import Text, { Heading01 } from '@Src/components/Text';
import * as styles from './processing.module.scss';
import Loader from '@Src/components/ui/Loader';
import { graphql } from 'gatsby';

export interface Props extends IPageProps {
	data: {
		contentfulPageTypeSignup: IPageData;
	};
}

const CompletePage = ({ location, pageContext, data, transitionStatus }: Props): ReactElement => {
	const { title, seoSettings } = data.contentfulPageTypeSignup;
	const { setTheme: setPageTheme } = usePageTheme();
	const { pageType, setPageType, setPageTitle, mode } = useApp();
	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

	useEffect(() => {
		if (pageType === 'index' && transitionStatus == 'exiting') {
			setLocalTransStatus(transitionStatus + 'Index');
		} else {
			setLocalTransStatus(transitionStatus);
		}

		if (transitionStatus == 'entering' || transitionStatus == 'entered') {

			setPageTheme(mode == 'day' ? 'yellow' : 'dark');

			setPageTitle(title);
			setPageType(pageContext.type);
		}
	}, [transitionStatus]);
	useEffect(() => {
		if (transitionStatus == 'entered') {
			setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'dark');
			console.log("mode", mode)
			//setPageTheme(mode == 'day' ? 'yellow' : 'dark');
		}
	}, [mode]);

	return (
		<ContentLayout transitionState={localTransStatus} fullWidth type={'signup'}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/>
			<div className={styles.flexCenter}> 
				<Heading01 theme={mode}>Sorry</Heading01>
				<Text theme={mode} htmlText={`We had a problem processing your signup`} />
			</div>
		</ContentLayout>
	);
};

export default CompletePage;
export const query = graphql`
	query {
		contentfulPageTypeSignup {
			... on IPageNode {
				...PageFields
			}
		}
	}
`;
